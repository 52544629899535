// merge summary count with component data
export function formatSummaries(summaries) {
  const generateSummaryOptions = (title, variant, icon = null) => ({
    title,
    variant,
    icon,
  })
  const generateSummaryDetails = (summaryOption, value) => ({
    ...summaryOption,
    value,
  })

  const summaryOptions = {
    total_invited_voucher: generateSummaryOptions('Invited', 'gray'),
    total_redeemed_voucher: generateSummaryOptions('Redeemed', 'gray'),
    total_pending_voucher: generateSummaryOptions('Pending', 'gray'),
    total_expired_voucher: generateSummaryOptions('Expired', 'gray'),
  }

  const formattedSummaries = []
  for (const k in summaryOptions) {
    formattedSummaries.push(
      generateSummaryDetails(summaryOptions[k], summaries[k])
    )
  }

  return formattedSummaries
}
