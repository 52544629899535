<template lang="">
  <section>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div>
            <TitlePlus
              :title="
                $t(
                  'components.riderDetailsManagement.diveIntoTab.vouchers.headline'
                )
              "
              :hide-plus="true"
            />
          </div>

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>
        <div class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row">
          <SummaryCard
            :title="
              $t(
                'components.riderDetailsManagement.diveIntoTab.vouchers.summary.invited'
              )
            "
            :value="` ${indexMetaData.summary.total_invited_voucher}`"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t(
                'components.riderDetailsManagement.diveIntoTab.vouchers.summary.redeemed'
              )
            "
            :value="` ${indexMetaData.summary.total_redeemed_voucher}`"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t(
                'components.riderDetailsManagement.diveIntoTab.vouchers.summary.pending'
              )
            "
            :value="` ${indexMetaData.summary.total_pending_voucher}`"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t(
                'components.riderDetailsManagement.diveIntoTab.vouchers.summary.expired'
              )
            "
            :value="` ${indexMetaData.summary.total_expired_voucher}`"
            variant="gray"
          />
        </div>
      </div>
      <FSTable
        :qso="qso"
        :fst-id="fstId"
        :headers="getTableHeaders"
        :endpoint="indexDataEndpoint"
        :text-fallback-always="true"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="fstId"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template>

        <template v-slot:default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" textFallbackAlways>
                <FSTableRowItem
                  :text="`#${item.voucher.id}`"
                  :truncate="-5"
                  :to="{
                    name: 'VoucherDetails',
                    params: { id: item.voucher.id },
                  }"
                />
                <FSTableRowItem :text="`#${item.id}`" :truncate="-5" />

                <FSTableRowItem :text="item.voucher.title" />
                <FSTableRowItem>
                  {{ getRedeemedAt(item.redeemed_at) }}
                </FSTableRowItem>
                <FSTableRowItem :text="item.voucher.voucher_type" />
                <FSTableRowItem>
                  {{ showVoucherCredit(item.voucher) }}
                </FSTableRowItem>
                <FSTableRowItem>
                  {{
                    getFormattedDateTime(item.voucher.expires_at, 'D MMM YYYY')
                  }}
                  <p>
                    (<TimeAgo
                      :title="item.voucher.expires_at"
                      :datetime="item.voucher.expires_at"
                      :auto-update="60"
                    />)
                  </p>
                </FSTableRowItem>

                <FSTableRowItem>
                  <x-status
                    :text="item.status"
                    :variant="getItemVarient(item.status)"
                  />
                </FSTableRowItem> </FSTableRow
            ></template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  :text="`#${item.voucher.id}`"
                  :truncate="-5"
                  :to="{
                    name: 'VoucherDetails',
                    params: { id: item.voucher.id },
                  }"
                />
                <FSTableRowItem :text="item.voucher.title" />
                <FSTableRowItem>
                  {{ showVoucherCredit(item.voucher) }}
                </FSTableRowItem>
                <FSTableRowItem>
                  <x-status
                    :text="item.status"
                    :variant="getItemVarient(item.status)"
                  />
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.redeemId'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      #{{ item.id | onlyLastFive }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.redeemedAt'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ getRedeemedAt(item.redeemed_at) }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.voucherType'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.voucher.voucher_type }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.expireDate'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          item.voucher.expires_at,
                          'D MMM YYYY'
                        )
                      }}
                      <p>
                        (<TimeAgo
                          :title="item.voucher.expires_at"
                          :datetime="item.voucher.expires_at"
                          :auto-update="60"
                        />)
                      </p>
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </section>
</template>
<script>
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import { VoucherConfig } from '@/config/VoucherConfig'
import { formatSummaries } from '../helpers'
import XStatus from '@/components/badge/XStatus'
import { getFormattedDateTime } from '@/utils/datetime'
export default {
  name: 'VoucherIndex',
  data: function() {
    return {
      id: '',
      indexDataEndpoint: '',
      details: {},
      indexMetaData: {
        summary: {
          total_invited_voucher: 0,
          total_redeemed_voucher: 0,
          total_pending_voucher: 0,
          total_expired_voucher: 0,
        },
      },
      qso: { append: '', prepend: '?' },
      fstId: 'voucherSummary',
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.voucherId'
          ),
          width: '10%',
          sort: 'voucher_id',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.redeemId'
          ),
          width: '10%',
          sort: 'redeem_id',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.voucherTitle'
          ),
          width: '20%',
          sort: 'title',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.redeemedAt'
          ),
          width: '10%',
          sort: 'redeemed_at',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.voucherType'
          ),
          width: '10%',
          sort: 'voucher_type',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.leftCredit'
          ),
          width: '10%',
          sort: 'balance',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.expireDate'
          ),
          width: '10%',
          sort: 'expires_at',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.status'
          ),
          width: '15%',
          sort: 'status',
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.voucherId'
          ),
          width: '20%',
          sort: 'voucher_id',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.voucherTitle'
          ),
          width: '30%',
          sort: 'title',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.leftCredit'
          ),
          width: '20%',
          sort: 'balance',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.vouchers.table.columns.status'
          ),
          width: '20%',
          sort: 'status',
        },
      ],
      filterOptions: [
        {
          key: 'status',
          type: 'checkbox',
          input: [
            { text: 'Invited', value: 'Invited' },
            { text: 'Redeemed', value: 'Redeemed' },
            { text: 'Pending', value: 'Pending' },
            { text: 'Expired', value: 'Expired' },
          ],
          title: 'Status',
        },
      ],
      dateFormat: {
        format: 'DD MMM YY',
      },
    }
  },
  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
    XStatus,
  },
  created() {
    this.id = this.$route.params.id
    this.indexDataEndpoint = VoucherConfig.api.riderHistory(this.id)
    this.getRiderAllVoucherDetails()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onApplyFilterDateRange,
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    async getRiderAllVoucherDetails() {
      let { data } = await this.$http.get(this.indexDataEndpoint)
      if (data) {
        this.details = data
      }
    },
    getRedeemedAt(redeemed_at) {
      if (redeemed_at) {
        return this.$UTCAwareTime(redeemed_at, this.dateFormat)
      }
      return '--'
    },
    getItemVarient(status) {
      console.log('item-status', status)
      if (status === 'Invited') return 'blue'
      if (status === 'Redeemed') return 'green'
      if (status === 'Pending') return 'orange'
      return 'red'
    },
    showVoucherCredit(voucher) {
      if (voucher.voucher_type) {
        let prefix =
          voucher.voucher_type === 'RV_CREDIT' ? this.currencySymbol : ''
        let postfix = voucher.voucher_type === 'RV_CREDIT' ? '' : 'mins'
        let amount =
          voucher.voucher_type === 'RV_CREDIT'
            ? voucher.reward_amount
            : parseInt(voucher.reward_amount)

        return `${prefix}${amount} ${postfix}`
      }
      return ''
    },
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
    getSummaries() {
      return formatSummaries(this.indexMetaData.summary)
    },
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
