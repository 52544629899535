export const VoucherConfig = {
  api: {
    // get
    index: '/dashboard/vouchers/rider-vouchers/',
    // post
    create: '/dashboard/vouchers/rider-vouchers/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/vouchers/rider-vouchers/${id}/`
    },
    pushVoucher: (id = 'uuid') => {
      return `/dashboard/vouchers/rider-vouchers/${id}/push/`
    },
    detail: (id = 'uuid') => {
      return `/dashboard/vouchers/rider-vouchers/${id}/`
    },
    history: (id = 'uuid') => {
      return `/dashboard/vouchers/rider-vouchers/${id}/redeem-history/`
    },
    riderHistory: (id = 'uuid') => {
      return `/dashboard/vouchers/rider-vouchers/rider-redeem-history/${id}/`
    },
    //delete
    delete: (id = 'uuid') => {
      return `/dashboard/vouchers/rider-vouchers/${id}/`
    },
  },
  events: {
    name: 'vouchers',
    // refresh-index-data
    refresh: `rid-voucher`,
    // slideover-right
    sorId: 'voucher',
    sorOpen: 'sor-open-voucher',
    sorClose: 'sor-close-voucher',
    sorToggle: 'sor-toggle-voucher',
    // editing-data
    editingData: 'edit-voucher-data',
    // viewing-data
    viewingData: 'view-voucher-data',
  },
}
